<template>
  <div class="tab-content" id="PTtab-11" :key="'PTtab-11'" >
    <div>
      <div class="pagenamPT">
        <h3 class="pagename2 w100w">요율설정</h3>
      </div>
      <p class="rateTxt">하부회원의 최대 요율은 내 상위요율을 넘을 수 없습니다. 최소요율은 해당회원의 하부 최대 요율보다 낮을 수 없습니다.</p>

      <div class="pagenamPT2">
        <h3>내 요율</h3>
      </div>
      <div class="strTablescr top">
         <div class="strTablePC">
            <table class="strTablePT" v-if="myRateInfo">
              <colgroup>
                <col width="">
              </colgroup>
              <thead>
                <tr>
                  <th></th>
                  <template v-for="cate in myRateInfo" :key="cate.cateCode">
                     <template v-if="cate.cateCodeName === 'HC-카지노'">
                        <th>호텔카지노</th>
                     </template>
                     <template v-else>
                        <th>{{ cate.cateCodeName }}</th>
                     </template>
                  </template>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>롤링(%)</th>
                  <template v-for="cate in myRateInfo" :key="cate.cateCode">
                    <td>{{ cate.pointRate }}</td>
                  </template>
                </tr>

                <tr>
                  <th>루징(%)</th>
                  <template v-for="cate in myRateInfo" :key="cate.cateCode">
                    <td>{{ cate.loseRate }}</td>
                  </template>
                </tr>
              </tbody>
            </table>
         </div>
         <div class="strTableM">
            <div class="strTablePTM">
                 <ul>
                   <template v-for="cate in myRateInfo" :key="cate.cateCode">
                      <template v-if="cate.cateCodeName === 'HC-카지노'">
                        <li>
                          <em>호텔카지노 롤링</em>
                          <span>{{ cate.pointRate }}</span>
                        </li>
                        <li>
                          <em>호텔카지노 루징</em>
                          <span>{{ cate.loseRate }}</span>
                        </li>
                     </template>
                     <template v-else>
                        <li>
                          <em>{{ cate.cateCodeName }} 롤링</em>
                          <span>{{ cate.pointRate }}</span>
                        </li>
                        <li>
                          <em>{{ cate.cateCodeName }} 루징</em>
                          <span>{{ cate.loseRate }}</span>
                        </li>
                     </template>
                   </template>
                 </ul>
            </div>
         </div>
      </div>

      <div class="pagenamPT2">
        <h3>하부 요율</h3>
      </div>
      <div class="strTablescr bottom">
         <div class="strTablePC">
            <div class="strTablePTIn">
               <table class="strTablePT tableA" v-if="bottomRateList">
                 <thead>
                 <tr>
                   <th rowspan="2">아이디</th>
                   <th rowspan="2">닉네임</th>
                   <th rowspan="2">파트너등급</th>
                 </tr>
                 </thead>
                 <tbody>
                     <template v-for="item in bottomRateList" :key="item.memId">
                       <tr>
                         <td><div>{{ item.memId }}</div></td>
                         <td><div>{{ item.memNick }}</div></td>
                         <td>
                            <div>
                              <template v-if="item.partnerLevelName === '대본사'">
                                <span class="box" :class="item.partnerLevel">본사</span>
                              </template>
                              <template v-else-if="item.partnerLevelName === '부본사'">
                                <span class="box" :class="item.partnerLevel">부본</span>
                              </template>
                              <template v-else-if="item.partnerLevelName === '일반회원'">
                                <span class="box" :class="item.partnerLevel">회원</span>
                              </template>
                              <template v-else>
                                <span class="box" :class="item.partnerLevel">{{item.partnerLevelName}}</span>
                              </template>
                            </div>
                         </td>
                       </tr>
                     </template>
                 </tbody>
               </table>

               <div class="strTablePTWrap">
                  <table class="strTablePT" v-if="bottomRateList">
                    <thead>
                    <tr>
                      <template v-for="cate in myRateInfo" :key="cate.cateCode">
                        <template v-if="cate.cateCodeName === '하이로우88'">
                          <th rowspan="2" colspan="2">{{ cate.cateCodeName }}</th>
                        </template>
                        <template v-else-if="cate.cateCodeName === 'HC-카지노'">
                          <th colspan="2">호텔카지노</th>
                        </template>
                        <template v-else>
                           <th colspan="2" >{{ cate.cateCodeName }}</th>
                        </template>
                      </template>
                    </tr>
                    <tr>
                      <template v-for="cate in myRateInfo" :key="cate.cateCode">
                        <template v-if="cate.detailSetYn === 'N'">
                          <th>롤링</th>
                          <th>루징</th>
                        </template>
                        <!--template v-else>
                        </template-->
                      </template>
                    </tr>
                    </thead>
                    <tbody>
                        <template v-for="item in bottomRateList" :key="item.memId">
                          <tr>
                            <template v-for="cate in item.botRateInfo" :key="cate.cateCode">
                              <template v-if="cate.detailSetYn === 'N'">
                                <td>
                                  <div class="rate">
                                    <input type="text" v-model="cate.pointRate" @change="onRateChange(cate, 'P', item)">
                                    <em>
                                      <i>최소({{cate.minPointRate}})</i>
                                      <i>최대({{cate.maxPointRate}})</i>
                                    </em>
                                  </div>
                                </td>
                                <td>
                                  <div class="rate">
                                    <input type="text" v-model="cate.loseRate"  @change="onRateChange(cate, 'R', item)">
                                    <em>
                                      <i>최소({{cate.minLoseRate}})</i>
                                      <i>최대({{cate.maxLoseRate}})</i>
                                    </em>
                                  </div>
                                </td>
                              </template>
                              <template v-else>
                                <td colspan="2">
                                  <button class="detailSet" @click="onToggleDetail(item, cate)">세부설정</button>
                                </td>
                              </template>
                            </template>
                          </tr>
                        </template>
                    </tbody>
                  </table>
               </div>

               <table class="strTablePT tableC" v-if="bottomRateList">
                 <thead>
                 <tr>
                   <th rowspan="2">저장</th>
                 </tr>
                 </thead>
                 <tbody>
                    <template v-for="item in bottomRateList" :key="item.memId">
                       <tr>
                         <td>
                            <div>
                              <a class="btnx sbtn" @click="onSave(item)">{{$t('front.stributor.save')}}</a>
                            </div>
                          </td>
                       </tr>
                    </template>
                 </tbody>
               </table>
            </div>
            <div class="detailSetWrap detailLosing" v-if="isDetailOpen">
             <table>
                <tbody>
                  <template v-for="rate in selectedMember.rateList" :key="rate.rateType">
                    <tr>
                      <td rowspan="2" class="tableheadside">{{rate.rateTypeName}}</td>
                      <td>롤링</td>
                      <td>
                        <button class="btnm">최소<i>({{rate.minPointRate}})</i></button>
                        <input type="text" :class="{'error': rate.errorP}" v-model="rate.pointRate"  @change="onRateChange(rate, 'P')">
                        <button class="btnx">최대<i>({{rate.maxPointRate}})</i></button>
                      </td>
                    </tr>
                    <tr>
                      <td>루징</td>
                      <td>
                        <button class="btnm">최소<i>({{rate.minLoseRate}})</i></button>
                        <input type="text" :class="{'error': rate.errorR}" v-model="rate.loseRate"  @change="onRateChange(rate, 'R')">
                        <button class="btnx">최대<i>({{rate.maxLoseRate}})</i></button>
                      </td>
                    </tr>
                  </template>
                </tbody>
             </table>
             <button class="detailSetSave" @click="onSaveDetail">저장</button>
             <span class="detailClose" @click="onToggleDetail()">×</span>
            </div>
         </div>

        <div class="strTableM">
            <div class="strTablePTM" v-if="bottomRateList">
               <template v-for="item in bottomRateList" :key="item.memId">
                 <ul>
                   <li><em>아이디</em><span>{{ item.memId }}</span></li>
                   <li><em>닉네임</em>{{ item.memNick }}</li>
                   <li><em>파트너등급</em><span class="box" :class="item.partnerLevel">{{item.partnerLevelName}}</span></li>
                   <template v-for="cate in item.botRateInfo" :key="cate.cateCode">
                      <template v-if="cate.detailSetYn === 'N'">
                        <template v-if="cate.cateCodeName === 'HC-카지노'">
                           <li>
                             <em>호텔카지노 롤링</em>
                             <div>
                               <input type="text" v-model="cate.pointRate" @change="onRateChange(cate, 'P', item)">
                               <span class="rate">
                                  <i>최소({{cate.minPointRate}})</i>
                                  <i>최대({{cate.maxPointRate}})</i>
                               </span>
                              </div>
                          </li>
                          <li>
                             <em>호텔카지노 루징</em>
                             <div>
                              <input type="text" v-model="cate.loseRate"  @change="onRateChange(cate, 'R', item)">
                              <span class="rate">
                                 <i>최소({{cate.minLoseRate}})</i>
                                 <i>최대({{cate.maxLoseRate}})</i>
                              </span>
                             </div>
                          </li>
                       </template>
                       <template v-else>
                          <li>
                            <em>{{ cate.cateCodeName }} 롤링</em>
                            <div>
                              <input type="text" v-model="cate.pointRate" @change="onRateChange(cate, 'P', item)">
                              <span class="rate">
                                 <i>최소({{cate.minPointRate}})</i>
                                 <i>최대({{cate.maxPointRate}})</i>
                              </span>
                             </div>
                         </li>
                         <li>
                            <em>{{ cate.cateCodeName }} 루징</em>
                            <div>
                             <input type="text" v-model="cate.loseRate"  @change="onRateChange(cate, 'R', item)">
                             <span class="rate">
                                <i>최소({{cate.minLoseRate}})</i>
                                <i>최대({{cate.maxLoseRate}})</i>
                             </span>
                            </div>
                         </li>
                       </template>
                      </template>
                      <template v-else>
                        <li>
                           <em>하이로우88</em>
                           <div><button class="detailSet" @click="onToggleDetail(item, cate)">세부설정</button></div>
                        </li>
                      </template>
                   </template>
                   <li><a class="btnx sbtn" @click="onSave(item)">{{$t('front.stributor.save')}}</a></li>
                 </ul>
               </template>
                <!--template v-else>
                 <ul>
                    <li>내역 없음</li>
                 </ul>
               </template-->
               <div class="detailSetWrap detailLosing" v-if="isDetailOpen">
                  <table>
                     <tbody>
                       <template v-for="rate in selectedMember.rateList" :key="rate.rateType">
                         <tr>
                           <td rowspan="2" class="tableheadside">{{rate.rateTypeName}}</td>
                           <td>롤링</td>
                           <td>
                             <button class="btnm">최소<i>({{rate.minPointRate}})</i></button>
                             <input type="text" :class="{'error': rate.errorP}" v-model="rate.pointRate"  @change="onRateChange(rate, 'P')">
                             <button class="btnx">최대<i>({{rate.maxPointRate}})</i></button>
                           </td>
                         </tr>
                         <tr>
                           <td>루징</td>
                           <td>
                             <button class="btnm">최소<i>({{rate.minLoseRate}})</i></button>
                             <input type="text" :class="{'error': rate.errorR}" v-model="rate.loseRate"  @change="onRateChange(rate, 'R')">
                             <button class="btnx">최대<i>({{rate.maxLoseRate}})</i></button>
                           </td>
                         </tr>
                       </template>
                     </tbody>
                  </table>
                  <button class="detailSetSave" @click="onSaveDetail">저장</button>
                  <span class="detailClose" @click="onToggleDetail()">×</span>
               </div>
            </div>
         </div>
      </div>
      <!------------------------------------------------------------------------------->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getRateInfo, memberNewRateUpdate, setMultipleRateInfo } from '@/api/retail'

export default {
  name: 'PartnerRate',
  components: {
  },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode',
      'siteIdInfo'
    ])
  },
  watch: {

  },
  data () {
    return {
      myRateInfo: null,
      bottomRateList: null,
      isDetailOpen: false,
      selectedMember: null
    }
  },
  async created () {
    await this.loadRate()
  },
  methods: {
    onSaveDetail () {
      this.emitter.emit('Loading', true)
      const cate = this.selectedMember
      console.log(cate)
      if (!cate.errorP && !cate.errorR) {
        const miniParam = {
          memId: cate.memId,
          vendorCode: cate.vendorCode,
          rateList: cate.rateList
        }

        console.log(miniParam)

        setMultipleRateInfo(miniParam).then(res => {
          if (res.resultCode !== '0') {
            alert(res.resultMessage)
          } else {
            alert('저장이 완료되었습니다.')
            this.onToggleDetail()
          }

          this.emitter.emit('Loading', false)
        })
      }
    },
    onToggleDetail (item, cate) {
      this.emitter.emit('Loading', true)
      console.log(item, cate)
      if (!this.isDetailOpen) {
        this.selectedMember = {
          ...cate.multipleRateInfo,
          errorP: item.errorP,
          errorR: item.errorR
        }
      } else {
        this.selectedMember = null
      }
      this.isDetailOpen = !this.isDetailOpen
      this.emitter.emit('Loading', false)
    },
    onSave (item) {
      console.log(item)
      this.emitter.emit('Loading', true)
      const isError = false

      let rateList = []

      if (item.botRateInfo) {
        item.botRateInfo.forEach(cate => {
          if (!cate.errorP && !cate.errorR) {
            // if (cate.detailSetYn === 'Y') {
            //   if (!cate.errorP && !cate.errorR) {
            //     const miniParam = {
            //       memId: item.memId,
            //       vendorCode: cate.cateCode,
            //       rateList: cate.multipleRateInfo.rateList
            //     }
            //
            //     setMultipleRateInfo(miniParam).then(res => {
            //       if (res.resultCode !== '0') {
            //         alert(res.resultMessage)
            //       }
            //     })
            //   }
            // }

            rateList.push({
              category: cate.category,
              cateCode: cate.cateCode,
              pointRate: cate.pointRate,
              loseRate: cate.loseRate
            })
          } else {
            rateList = []
            return false
          }
        })
      }

      console.log(rateList, isError)

      if (rateList.length > 0 && !isError && item.botRateInfo.length === rateList.length) {
        const params = {
          siteId: this.siteIdInfo.siteId,
          memId: item.memId,
          rateList
        }

        memberNewRateUpdate(params).then(res => {
          if (res.resultCode === '0') {
            alert('저장 완료되었습니다.')
          } else {
            alert(res.resultMessage)
          }
          this.emitter.emit('Loading', false)
        })
      } else {
        this.emitter.emit('Loading', false)
      }
    },
    onRateChange (item, type, category) {
      if (category) {
        category['error' + type] = false
      } else {
        item['error' + type] = false
      }

      const max = type === 'P' ? item.maxPointRate : item.maxLoseRate
      const min = type === 'P' ? item.minPointRate : item.minLoseRate
      const value = type === 'P' ? item.pointRate : item.loseRate

      if (Number(value) > Number(max)) {
        if (category) {
          category['error' + type] = true
        } else {
          item['error' + type] = true
        }
      } else if (Number(min) > Number(value)) {
        if (category) {
          category['error' + type] = true
        } else {
          item['error' + type] = true
        }
      }
    },
    loadRate () {
      this.emitter.emit('Loading', true)
      getRateInfo({}).then(res => {
        console.log(res)
        const result = res.data

        if (result.resultCode === '0') {
          this.myRateInfo = result.data.myRateInfo
          this.bottomRateList = result.data.botttomRateList

          this.bottomRateList.forEach(item => {
            item.errorP = false
            item.errorR = false
          })
        }

        this.emitter.emit('Loading', false)
      })
    }
  }
}
</script>
<style scoped src="@/styles/striNew.css"></style>
<style scoped>
.pagenamPT2 {font-size: 16px;font-weight: bold;margin: 20px 0;position: relative;color: #121212;display: flex;align-items: center;}
.strTablePTWrap {display: flex;width: calc(100% - 440px); overflow: auto;}
.strTablePTWrap table {white-space: nowrap;border-collapse: collapse;}
.strTablePTWrap th {padding: 0 5px;}
.strTablePTIn {display: flex;align-items: flex-start;min-width: 1430px;max-width: 1800px;}
.tableA th, .tableC th {height: 92px !important;}
.tableA div, .tableC div {
  min-height: 35.8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tableA {width: 300px !important;}
.tableC {width: 140px !important;}
.strTablePT {border-top: 5px solid #82a6a4;}
.top .strTablePT {display: table;table-layout: fixed;}
.strTablePT th {background: #EEF0F5 !important;border: 1px solid #e1e0e0;}
.strTablePT tr td {background: #fff !important;}
.strTablePT tr td i {font-size: 12px;color: #1200ff;}
.strTablePT tr td i:first-child {color: #f00;}
.strTablePT tr td .rate {display: flex;align-items: center;justify-content: center;gap: 5px;padding-left: 5px;box-sizing: border-box; min-height: 35.8px;}
.strTablePT tr td .rate em {display: flex;flex-direction: column;gap: 2px;width: 62px;align-items: flex-start;}
table input[type=text], .bottom input[type=text] {width: 50px;height: 28px;padding: 0 0 0 5px;margin: 0;border-radius: 0;border: 1px solid #bdbdbd;box-sizing: border-box;}
table .btnm, table .btnx, .bottom .btnm, .bottom .btnx {height: 28px;border-radius: 5px;font-size: 12px;margin: 0 auto;cursor: pointer;}
.sbtn {display: flex;  align-items: center;justify-content: center;}
.rateTxt {font-size: 12px;line-height: 1.5em;margin-bottom: 10px;}
.detailSetWrap {position: fixed !important;}
.detailSetWrap.detailLosing {background: #fff;border: 1px solid #C2C2C2;}
table .detailSet, ul .detailSet {background-image: linear-gradient(to bottom, #72a2c1, #5f8bb1);border: 0;color: #fff;height: 28px;border-radius: 5px;}
.detailSetWrap tr > td:last-child input {padding-left: 5px;box-sizing: border-box;}
.detailClose {color: #000 !important;}
@media (max-width: 1000px) {
   .strTablePTM li:nth-child(4) {width: 50% !important;}
   .bottom .strTablePTM li {width: 100% !important;}
   .strTablePTM em {width: 100px !important;}
   .strTablePTM li .rate i:first-child {color: #f00;}
   .strTablePTM li .rate i {font-size: 12px;color: #1200ff;margin-left: 5px;}
}
</style>
